<template>
  <v-dialog
    v-model="isOpen"
    max-width="700"
    @input="handleClose"
  >
    <v-card>
      <v-card-title class="headline pb-10">
        Вы точно хотите удалить эту форму {{currentForm.name}}?
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close()">Закрыть</v-btn>
        <v-btn text color="primary" @click="deleteForm">Удалить</v-btn>
      </v-card-actions>
    </v-card>
    <OverlayLoader :show="isLoading"></OverlayLoader>
  </v-dialog>
</template>

<script>
import OverlayLoader from '@/app/shared/components/app-loader/Loader';

export default {
  name: 'confirmFormDeletionModal',
  components: {
    OverlayLoader,
  },
  data() {
    return {
    };
  },
  computed: {
    isOpen: {
      get(){
        return this.$store.getters.GET_FORMS_DETAILS_MODAL_CONFIRM_DELETION;
      },
      set(){
        this.$store.commit('SET_CONFIRM_DELETION_FORM_MODAL', false);
      }
    },
    isLoading: {
      get(){
        return this.$store.getters.GET_FORMS_DETAILS_MODAL_CONFIRM_DELETION_LOADING;
      },
      set(value){
        this.$store.commit('SET_CONFIRM_DELETION_FORM_MODAL_LOADING', value);
      }
    },
    currentForm: {
      get(){
        return this.$store.getters.GET_CURRENT_FORM;
      },
      set(value){
        this.$store.commit("SET_CURRENT_FORM", value);
      }
    },
  },
  methods: {
    handleClose(isOpen) {
      if (!isOpen) {
        this.close();
      }
    },
    close() {
      this.currentForm = {};
      this.isLoading = false;
      this.isOpen = false;
    },
    deleteForm(){
      this.$store.dispatch('DELETE_FORM', this.currentForm.id)
        .then(() => {
          this.$toast.success('Форма была удалена');
          this.close();
        })
        .catch(() => {
          this.$toast.error('Неизвестная ошибка');
        });
    }
  },
};
</script>
