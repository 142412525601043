<template>
  <div>
    <div class="columns">
      <div class="column">
        <h2 class="title is-3">Список форм обратной связи</h2>
      </div>
      <div class="column">
        <b-button
          class="is-pulled-right"
          type="is-primary"
          icon-right="plus"
          v-if="isAdmin"
          @click="createForm"
        >Добавить форму</b-button>
      </div>
    </div>

    <!-- ТАБЛИЦА -->
    <b-table
      :data="forms.results"
      class="users-table"

      paginated
      backend-pagination
      :total="forms.total"
      :per-page="params.perPage"
      @page-change="onPageChange"
    >
      <b-table-column label="ID" v-slot="props">
        <span>{{ props.row.id }}</span>
      </b-table-column>

      <b-table-column label="Имя" v-slot="props">
        <span>{{ props.row.name }}</span>
      </b-table-column>

      <b-table-column label="Превью" v-slot="props">
        <div class="image" style="width: 150px;">
          <img
            :src="props.row.preview_path" />
        </div>
      </b-table-column>

      <b-table-column label="Действия" v-slot="props" v-if="isAdmin">

        <div>
          <b-tooltip label="Редактировать">
            <b-button class="edit_icon" @click="updateForm(props.row.id)">
              <svg viewBox="0 0 24 24" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4"></path>
                <path d="M13.5 6.5l4 4"></path>
              </svg>
            </b-button>
          </b-tooltip>

          <b-tooltip label="Удалить">
            <b-button
              class="delete_icon"
              @click="deleteForm(props.row)">
              <svg viewBox="0 0 24 24" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                <path d="M4 7l16 0"></path>
                <path d="M10 11l0 6"></path>
                <path d="M14 11l0 6"></path>
                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
              </svg>
            </b-button>
          </b-tooltip>
        </div>

      </b-table-column>

      <!-- Пагинация и выбор количества элементов на странице -->
      <template slot="bottom-left">
        <div class="pagination-controls">
          <v-select
            style="width: fit-content; float: left;"
            v-model="params.perPage"
            @change="getForms"
            :items="perPageOptions"
            item-text="name"
            item-value="value"
            outlined
            hide-details
            dense
          />
        </div>
      </template>

    </b-table>

    <OverlayLoader :show="isLoading"></OverlayLoader>
    <FormDetailsModal />
    <FormConfirmDeleteModal />

  </div>
</template>

<script>
import FormDetailsModal from '@/app/client/forms/shared/modals/formDetailsModal.vue';
import FormConfirmDeleteModal from '@/app/client/forms/shared/modals/confirmDeletionFormModal.vue';
import OverlayLoader from "@/app/shared/components/app-loader/Loader";

export default {
  name: "formsList",
  components: {
    OverlayLoader,
    FormDetailsModal,
    FormConfirmDeleteModal
  },
  data() {
    return {
      params: {
        type: '',

        // пагинация
        perPage: 20,
        page: 1
      },
      perPageOptions: [
        { name: "10 на странице", value: 10 },
        { name: "20 на странице", value: 20 },
        { name: "50 на странице", value: 50 },
        { name: "100 на странице", value: 100 },
      ],
    };
  },
  created() {
    this.getForms();
  },
  watch: {
    detailOpened(newVal) {
      if (!newVal) {
        this.getForms();
      }
    },
    deletionModalOpened(newVal) {
      if (!newVal) {
        this.getForms();
      }
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters.IS_ADMIN;
    },
    forms() {
      return this.$store.getters.GET_FORMS;
    },
    isLoading: {
      get() {
        return this.$store.getters.IS_FORMS_LOADING;
      },
      set(value) {
        this.$store.commit("SET_FORMS_LOADING", value);
      },
    },
    detailOpened: {
      get() {
        return this.$store.getters.GET_FORM_DETAILS_MODAL_OPENED;
      },
      set(value) {
        this.$store.commit("SET_FORM_DETAILS_MODAL_OPENED", value);
      },
    },
    deletionModalOpened: {
      get() {
        return this.$store.getters.GET_FORMS_DETAILS_MODAL_CONFIRM_DELETION;
      },
      set(value) {
        this.$store.commit('SET_CONFIRM_DELETION_FORM_MODAL', value);
      }
    }
  },
  methods: {
    onPageChange(page) {
      this.params.page = page;
      this.getForms();
    },
    getForms() {
      this.$store.dispatch("GET_FORMS", this.params).catch(() => {
        this.$toast.error("Ошибка при получении данных");
      });
    },
    updateForm(id) {
      this.$store.dispatch("GET_FORM_BY_ID", id).then(() => {
        this.detailOpened = true;
      }).catch(() => {
        this.$toast.error("Ошибка при получении данных");
      });
    },
    deleteForm(form) {
      this.$store.commit("SET_CURRENT_FORM", form);
      this.deletionModalOpened = true;
    },
    createForm() {
      this.$store.commit("SET_CURRENT_FORM", {});
      this.detailOpened = true;
    },
  },
};
</script>

<style>
</style>
